import { useContext } from "react";

import { MangaContext } from '../context/mangaContext'

const useManga = () => {
  const context = useContext(MangaContext);
  //console.log(context)
  if (!context) {
    throw new Error("AuthContext must be placed within AuthProvider");
  }

  return context;
};

export default useManga;