// Chakra imports
import {
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    Spacer,
    useColorModeValue,
  } from "@chakra-ui/react";
import { PhoneIcon, AddIcon, WarningIcon, ChevronDownIcon } from '@chakra-ui/icons'
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import Image from 'assets/img/images.jpg'
import Img from 'assets/img/img.jpg'
import React, {useEffect} from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import useManga from "hook/useManga";
import { useLocation} from "react-router-dom";
import { NavLink } from "react-router-dom";
import { HashRouter, Route, Redirect } from "react-router-dom";

const Lecture = () => {
 // sessionStorage.setItem('chakra-ui-color-mode','dark')
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
    const bgButton =  useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "gray.800"
      ); 
    const bgButtonFooter = useColorModeValue(
      "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
      "gray.600"
    );
    const headerButtonStyle = {
       base: "100px", sm:"5px", md:"150px", lg: "290px", xl: "400px" 
    }
    const footerButtonStyle = {
       base: "100px", sm:"5px", lg: "100px", xl: "100px" 
   }
    let navbarPosition = "fixed";
    let colorButton = "white";
    const { getManga, mangaTitle, scanNumber, 
            pageNumber, pages, scanList, url, 
            scanMax, pagesMax, scanMin, lastPagesMax } = useManga()

    let location = useLocation();
    let manga = ''
    let scan = ''
    let page = 200
    try {
       manga = location.search.split('=')[1].split('&')[0]
       scan = location.search.split('=')[2].split('&')[0]
       page = location.search.split('=')[3]
    } catch (error) {
    }
    
    
    useEffect(() => {
      const initialize = async () => {
          await getManga(manga,scan,page);
      }
      initialize();
    }, [page,scan]);
    
    const handleClickNext = (size,bgB) => {
      if(scanMax >= scanNumber && page < pagesMax){
        const pagex =  parseInt(page) + parseInt(1)
        return (<NavLink to={`?manga=${manga}&scan=${scan}&num=${pagex}`} > 
        <BoxButton msg={'Page suivante'} bgButton={bgB} colorButton={colorButton} size={size}/>
        </NavLink>)
      }else if(scanMax > scanNumber && page == pagesMax){
        const pagex =  parseInt(scanNumber) + parseInt(1)
        return (<NavLink to={`?manga=${manga}&scan=${pagex}&num=${0}`} > 
          <BoxButton msg={'Page suivante'} bgButton={bgB} colorButton={colorButton} size={size}/>
        </NavLink>)
      }else {
       return <BoxButton msg={'Page suivante'} bgButton={bgB} colorButton={colorButton} size={size}/>     
      }
    }

    const handleClickLast = (size,bgB) => {
      if(page > 0){
        const pagex =  parseInt(page) - parseInt(1)
        return (<NavLink to={`?manga=${manga}&scan=${scan}&num=${pagex}`} > 
        <BoxButton msg={'Page Précédente'} bgButton={bgB} colorButton={colorButton} size={size}/>
        </NavLink>)
      }else if(scanMin < scanNumber && page == 0){
        const pagex =  parseInt(scanNumber) - parseInt(1)
        return (<NavLink to={`?manga=${manga}&scan=${pagex}&num=${lastPagesMax}`} > 
          <BoxButton msg={'Page Précédente'} bgButton={bgB} colorButton={colorButton} size={size}/>
        </NavLink>)
      }else {
       return <BoxButton msg={'Page Précédente'} bgButton={bgB} colorButton={colorButton} size={size}/>     
      }
    }
      /* if (props.secondary === true) {
        navbarIcon = "white";
        navbarBg = "none";
        navbarBorder = "none";
        navbarShadow = "initial";
        navbarFilter = "initial";
        navbarBackdrop = "none";
        bgButton = "white";
        colorButton = "gray.700";
        mainText = "white";
        navbarPosition = "absolute";
      } */
    return (
      <React.Fragment>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Box
          position="absolute"
          minH={{ base: "70vh", md: "50vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          borderRadius={{ md: "15px" }}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          bgImage={Img}
          bgSize="cover"
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}
        ></Box>
        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          align="center"
          mt="6.5rem"
          mb="30px"
        >
          {/* <Text fontSize="4xl" color="white" fontWeight="bold">
            Nom du Manga
          </Text> */}
          <Flex >
            <Box p="4"  >
            <Menu >
              <MenuButton bg={bgButton} as={Button}  rightIcon={<ChevronDownIcon />}>
                  {scan}
              </MenuButton>
              <MenuList>
                  {scanList.map( (data, index) => {
                    return (
                      <NavLink key={index} to={`?manga=${manga}&scan=${data}&num=${0}`} > 
                        <MenuItem key={index}>{data}</MenuItem>
                      </NavLink>
                   )})}
              </MenuList>
            </Menu>
          </Box>
          <Spacer />
          <Box p="4" >
            <Menu >
              <MenuButton bg={bgButton} as={Button} rightIcon={<ChevronDownIcon />}>
                  {page}
              </MenuButton>
              <MenuList>
                  {pages.map( (data, index) => {
                    return (
                      <NavLink key={index} to={`?manga=${manga}&scan=${scan}&num=${index}`} > 
                        <MenuItem key={index}>{index}</MenuItem>
                      </NavLink>
                   )})}
              </MenuList>
            </Menu>
          </Box>
          <Spacer />
          </Flex>
          <Flex >
          {handleClickLast(headerButtonStyle,bgButton)}
          <Spacer />
          {handleClickNext(headerButtonStyle,bgButton)}
        </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w={{ base: "90%", sm: "120%", lg: "90%", xl: "80%" }}/* {{
                lg:"845px",
                sm: "400px",
                xs: "auto"
               }} */
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px", sm:"5px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <img src={url} alt="(" />
          </Flex>
        </Flex>
      </Flex>
      <Flex >
          {handleClickLast(footerButtonStyle,bgButtonFooter)}
          <Spacer />
          {handleClickNext(footerButtonStyle,bgButtonFooter)}
      </Flex>
      </React.Fragment>
    );
  }


  const BoxButton = (props) => {
    const {msg, colorButton, bgButton, size} = props
    return(
      <Box p="4" >
                <Button
                bg={bgButton}
                color={colorButton}
                fontSize="xs"
                variant="no-hover"
                borderRadius="35px"
                px="30px"
                mx={size}
                //ml={{ base: "100px", sm:"5px", lg: "250px", xl: "400px" }}
                display={{
                    sm: "flex",
                    lg: "flex",
                }}
                >
                {msg}
                </Button>
          </Box>
    )
  }
  
  export default Lecture;
  