import axios from "axios"

const domaine ='https://op-5p43dyptxq-uc.a.run.app'

const options = { 
  method: "GET",
  url: domaine,
};

export const getOnePiece = () => {
    
    return new Promise((onSuccess, onFail) => {
        
        axios(options).then((response, error) => {
            if (!response || error) {
                onFail(`Response failure ${error}`)
                console.log("fail")
                return false
            }
            //console.log(response)
            onSuccess(response.data)
        })
    })
}

export const getOnePieceDescription = () => {
    return new Promise((onSuccess, onFail) => {
        axios({ 
            method: "GET",
            url: `${domaine}/descritionOp`,  
        }).then((response, error) => {
            if (!response || error) {
                console.log("Fail")
                onFail(`Response failure ${error}`)
                return false
            }
            onSuccess(response.data)
            //onSuccess(response.data)
        })
    })
}
