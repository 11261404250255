import { createContext, useReducer, useEffect } from "react";
import {getOnePiece, getOnePieceDescription} from '../services/index'

const GET = "GET";
const GET_MANGA = "GET_MANGA";
const GET_SYNOPSIS = "GET_SYNOPSIS";

const initialState = {
    mangaTitle: '',
    scanNumber: '',
    pageNumber: '',
    url: '',
    pages: [],
    pagesMax: 0,
    scanMax : 0,
    scanList: [],
    synopsis: '',
    banderole: '',
    profile: '',
    firstPageList: [],
    author: '',
    date: '',
    mangaT: '',
    mangaList: []
};

const reducer = (state, action) => {
  switch (action.type) {

    case GET_MANGA:
        return {
            ...state,	
            scanNumber: action.payload.scanNumber,
            pages: action.payload.pages,
            scanList: action.payload.scanList,
            url: action.payload.url,
            pagesMax: action.payload.pagesMax,
            scanMax: action.payload.scanMax,
            scanMin: action.payload.scanMin,
            lastPagesMax: action.payload.lastPagesMax,
            firstPageList: action.payload.firstPageList,
        };

    case GET_SYNOPSIS:
      return {
          ...state,
          banderole: action.payload.banderole,
          profile: action.payload.profile,
          synopsis: action.payload.synopsis,
          mangaTitle: action.payload.mangaTitle,
          author: action.payload.author,
          date: action.payload.date,
      };

      case GET:
        return {
            ...state,
            mangaList: action.payload.mangaList,
        };

		default:
			return state;
	}	
 
};

const MangaContext = createContext(null);

function MangaProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
        localStorage.setItem('chakra-ui-color-mode','dark')
        const list = []
        const params = await getOnePieceDescription()
        const paramsL = await getOnePiece()
        const manga = {
          title: params[0].mangaTitle,
          icon: params[0].banderole,
          description: params,
          scans: paramsL
        }
        list.push(manga)
        dispatch({ 
          type: GET, 
          payload: { 
            mangaList: list, 
          } 
        })
        /* faire une liste dans firebase avec tout les noms de fonctions associer a un manga */
    }
    initialize();
  }, []);

  /* chakra-ui-color-mode */
  const getMangaName = async () =>{
    const params = /* await initialState.mangaList[0].description[0] */await getOnePieceDescription()
    const synopsis = params[0].synopsis
    const mangaTitle = params[0].mangaTitle
    const profile = params[0].profile
    const banderole = params[0].banderole
    const author = params[0].author
    const date = params[0].date
    console.log(author, date)
    dispatch({ 
      type: GET_SYNOPSIS, 
      payload: { 
        synopsis: synopsis,
        mangaTitle: mangaTitle,
        banderole: banderole,
        profile: profile,
        author: author,
        date: date,
      } 
    })
  }
  const getManga = async (manga,scan,page) => {
    const params = await getOnePiece()
    const list = []
    const pagesList = []
    const firstPageList = []
    let Scan = 'invalid'
    let url = ''
    let max = -1
    let maxx = -1
    let scanMax = 0
    let scanMin = 0
    params.map( (data, index) => {
      if(index <5){
        const obj = {
          titre: data.titre,
          url: data.pages[0]
        }
        firstPageList.push(obj)
      }
      if (data.titre>scanMax){
       scanMax = data.titre
      }
      if (scanMin == 0 ){
        scanMin = data.titre
      }else if( data.titre < scanMin){
        scanMin = data.titre
      }
      if (data.titre == parseInt(scan)-parseInt(1)){
        data.pages.map( (d,i) => {
          maxx ++    
        })
      }
      if (data.titre === scan){
        Scan = data.titre
        data.pages.map( (d,i) => {
          max ++
          pagesList.push(d)
          if(page == i){
            url = d
          }     
        })
      }    
      list.push(data.titre)
    })

    dispatch({ 
      type: GET_MANGA, 
      payload: { 
        scanNumber: Scan,
        url: url,
        pages: pagesList,
        scanList: list,
        pagesMax: max,
        lastPagesMax: maxx,
        scanMax: scanMax,
        scanMin: scanMin,
        firstPageList: firstPageList,
      } 
      })
  }

  return (
    <MangaContext.Provider
      value={{
        ...state,
        /* produitsGet,
        magasinGet,
        addProduit,
        supProduit, */
        getMangaName,
        getManga
      }}
    >
      {children}
    </MangaContext.Provider>
  );
}

export { MangaContext, MangaProvider };